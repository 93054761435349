.services-sec {
  position: relative;

  .inner {
    padding: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 80px;
    max-width: 1390px;
    margin: 0px auto;
    .first,
    .second {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 100px;
      .left {
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 22px;
        width: 60%;
        h3 {
          color: var(--headingcolor);
          line-height: 100%;
          text-transform: none;
        }
        span {
          position: absolute;
          top: -84px;
          right: 0;
          font-size: 240px;
          font-family: "Montserrat";
          line-height: 100%;
          opacity: 0.1;
        }
        .btnprimary {
          width: fit-content;
        }
      }
      .right {
        width: 40%;
        height: 100%;
        max-width: 566px;
        max-height: 720px;
        object-fit: cover;
        border-radius: 12.025px;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.43);
      }
    }
    .second {
      flex-direction: row-reverse;
    }
  }
  .left-back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
  }
  .right-back {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }
}

@media screen and (max-width: 1500px) {
  .services-sec {
    .inner {
      padding: 100px 80px;
      .first,
      .second {
        column-gap: 50px;
        .left {
          row-gap: 20px;

          span {
            top: -60px;
            font-size: 200px;
          }
        }
        .right {
          max-width: 566px;
          max-height: 500px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .services-sec {
    .inner {
      padding: 20px;
      row-gap: 20px;
      .first,
      .second {
        flex-direction: column;
        gap: 20px;
        .left {
          flex-direction: column;
          row-gap: 10px;
          width: 100%;

          span {
            position: absolute;
            top: 0px;
            right: 0;
            font-size: 100px;
            line-height: 100%;
          }
          .btnprimary {
            width: fit-content;
          }
        }
        .right {
          width: 100%;
          max-width: 350px;
          max-height: 400px;
          border-radius: 10px;
        }
      }
      .second {
        flex-direction: column;
      }
    }
  }
}
