.homeservies-sec {
  padding: 100px 0px 0px 0px;
  background: var(--bodycolor);
  .head {
    background: var(--gradient);
    padding: 11.63px 160px 17.37px 140px;
    width: fit-content;
    border-radius: 20px 0px 0px 20px;
    margin-left: auto;
    margin-bottom: 70px;
    h3 {
      color: white;
    }
  }
  .services-accordian {
    padding: 0px 70px;
    margin: 0px auto;
    .accordion {
      background: transparent;
    }
    .accordion-button,
    .accordion-body {
      padding: 0px;
    }

    .accordion-item,
    .accordion-header,
    .accordion-button {
      background: transparent;
    }
    .accordion-button::after {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      margin-left: auto;
      content: "";
      background-image: url("/assets/images/accordion.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transition: var(--bs-accordion-btn-icon-transition);
    }
    .accordion-button:not(.collapsed)::after {
      transform: rotate(135deg) !important;
    }
    .accordion-item {
      border: none;
      padding: 30px 25px;
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      position: relative;
      &::before {
        max-width: 100%;
        content: "";

        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0.7%,
          #000 51.9%,
          rgba(0, 0, 0, 0) 100%
        );
        z-index: 10;

        // content: url("/assets/images/accordionborder.webp");
        // background-repeat: no-repeat;
        // background-position: center;
        // background-size: 100%;

        // position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
      }
    }
    .accordion-item:first-child {
      &::after {
        max-width: 100%;
        content: "";

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0.7%,
          #000 51.9%,
          rgba(0, 0, 0, 0) 100%
        );
        z-index: 10;
      }
    }

    .accordian-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 80px;
      h6 {
        color: var(--textcolor);
        max-width: 600px;
      }
      img {
        max-width: 575.198px;
        max-height: 325.678px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12.025px;
      }
    }
    .accordion-button {
      box-shadow: unset;
      color: var(--headingcolor);
      font-family: "Montserrat";
      font-size: 36.075px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      &:focus {
        box-shadow: unset;
        color: unset;
      }
    }
    .accordion-button:not(.collapsed) {
      background: unset;
      box-shadow: unset;
    }
  }
}

@media screen and (max-width: 1500px) {
  .homeservies-sec {
    padding: 50px 0px 0px 0px;
    background: var(--bodycolor);
    .head {
      padding: 10px 100px 14px 100px;
      margin-bottom: 40px;
    }
    .services-accordian {
      padding: 0px 40px;

      .accordion-button::after {
        width: 30px;
        height: 30px;
      }

      .accordion-item {
        padding: 25px 20px;

        row-gap: 20px;
      }

      .accordian-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 60px;

        img {
          max-width: 400px;
          max-height: 280px;
        }
      }
      .accordion-button {
        font-size: 30px;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .homeservies-sec {
    padding: 50px 0px 0px 0px;
    background: var(--bodycolor);
    .head {
      padding: 10px 70px 14px 70px;

      margin-bottom: 40px;
    }
    .services-accordian {
      .accordion-button {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .homeservies-sec {
    padding: 20px 0px 0px 0px;
    .head {
      padding: 10px 50px 10px 50px;
      border-radius: 10px 0px 0px 10px;
      margin-bottom: 20px;
    }
    .services-accordian {
      padding: 0px 20px;
      margin: 0px auto;

      .accordion-button::after {
        width: 20px;
        height: 20px;
      }
      .accordion-button:not(.collapsed)::after {
        transform: rotate(135deg) !important;
      }
      .accordion-item {
        padding: 20px 0px;

        row-gap: 20px;
        position: relative;
      }

      .accordian-content {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        h6 {
          color: var(--textcolor);
          max-width: 600px;
        }
        img {
          border-radius: 10px;
        }
      }
      .accordion-button {
        box-shadow: unset;
        color: var(--headingcolor);
        font-family: "Montserrat";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        &:focus {
          box-shadow: unset;
          color: unset;
        }
      }
      .accordion-button:not(.collapsed) {
        background: unset;
        box-shadow: unset;
      }
    }
  }
}
