.contactuspage-sec {
  display: flex;
  flex-direction: column;
  .contactbanner {
    height: 700px;
    background: url("/assets/images/contactbanner.webp");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    color: white;
    gap: 112px;
    .center {
      line-height: 100%;
    }
    .bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 132px;
      .emails {
        display: flex;
        align-items: center;
        column-gap: 48px;
        div {
          border-radius: 34.5px;
          border: 2px solid #2abe02;
          padding: 19px 70px;
        }
      }
      .address {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 22px;
      }
    }
  }
  .contact-content {
    background: url("/assets/images/contactpageback.webp");
    background-color: var(--bodycolor);
    padding: 100px;
    .contact-card {
      position: relative;
      max-width: 1440px;
      margin: 0px auto;
      border-radius: 50px;
      background: #fff;
      box-shadow: 0px 141px 200px -80px rgba(25, 58, 75, 0.3);
      padding: 131px 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 50px;
      overflow: hidden;
      .left {
        display: flex;
        flex-direction: column;
        row-gap: 18px;
        max-width: 545px;
        width: 100%;
        position: relative;
        z-index: 2;
        input,
        textarea {
          border-radius: 6px;
          border: 1px solid var(--Gray-5, #e0e0e0);
          padding: 13px 20px;
          outline: none;
          color: black;
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          letter-spacing: 0.14px;
          &::placeholder {
            color: var(--Gray-3, #828282);
          }
        }
        p {
          font-weight: 600;
          color: black;
        }
        .checkboxes {
          display: flex;
          flex-wrap: wrap;
          gap: 10px 15px;
          .round {
            position: relative;
            display: flex;
            align-items: center;
            gap: 15px;
            cursor: pointer;
            .circle-box {
              background-color: #fff;
              border: 1px solid #ccc;
              border-radius: 50%;
              cursor: pointer;
              height: 16px;
              left: 0;
              cursor: pointer;
              position: absolute;
              top: 50%;
              bottom: 50%;
              transform: translate(0%, -50%);
              width: 16px;
            }
          }

           

          .round .circle-box:after {
            border: 2px solid #fff;
            border-top: none;
            border-right: none;
            content: "";
            height: 5px;
            left: 2px;
            opacity: 0;
            position: absolute;
            top: 4px;
            transform: rotate(-45deg);
            width: 10px;
          }

          .round input[type="checkbox"] {
            visibility: hidden;
          }

          .round input[type="checkbox"]:checked + label {
            background-color: black;
            border-color: black;
          }

          .round input[type="checkbox"]:checked + label:after {
            opacity: 1;
          }
        }
        .btnprimary {
          width: fit-content;
        }
      }
      .right {
        border-radius: 15px;
        box-shadow: 12px 7px 58.5px -80px rgba(42, 190, 2, 0.19);
        position: relative;
        z-index: 2;
        iframe {
          width: 545px;
          height: 700px;
        }
      }
      .colored {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 25%;
        opacity: 0.5;
        background: linear-gradient(
          106deg,
          #9af123 10.92%,
          #81e61b 30.36%,
          #44ca09 71.59%,
          #2abe02 88.71%
        );
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .contactuspage-sec {
    .contactbanner {
      height: 600px;
      gap: 100px;
      .center {
        line-height: 100%;
      }
      .bottom {
        gap: 100px;
        .emails {
          column-gap: 30px;
          div {
            border-radius: 30px;
            padding: 15px 50px;
          }
        }
        .address {
          padding-bottom: 20px;
        }
      }
    }
    .contact-content {
      .contact-card {
        padding: 50px 70px;
        column-gap: 40px;
        .left {
          row-gap: 15px;
          input,
          textarea {
            padding: 10px 15px;

            line-height: 100%;
          }
          .checkboxes {
            div {
              column-gap: 10px;
            }
          }
        }
        .right {
          iframe {
            width: 400px;
            height: 500px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .contactuspage-sec {
    .contactbanner {
      height: 500px;
      gap: 70px;

      .bottom {
        gap: 80px;
        .emails {
          column-gap: 20px;
          div {
            border-radius: 30px;
            padding: 10px 30px;
          }
        }
      }
    }
    .contact-content {
      padding: 60px;
      .contact-card {
        padding: 40px 60px;
        column-gap: 30px;
        .left {
          row-gap: 10px;
          input,
          textarea {
            padding: 10px 15px;
          }
        }
        .right {
          iframe {
            width: 400px;
            height: 500px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .contactuspage-sec {
    .contactbanner {
      height: unset;
      padding: 60px 20px;
      gap: 60px;
      .bottom {
        gap: 60px;
        .emails {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 20px;
          div {
            padding: 10px 40px;
          }
        }
        .address {
          padding-bottom: 0px;
        }
      }
    }
    .contact-content {
      padding: 20px;
      .contact-card {
        border-radius: 20px;
        padding: 20px;

        flex-direction: column;
        gap: 30px;
        .left {
          row-gap: 10px;
          input,
          textarea {
            padding: 10px 15px;
            font-size: 14px;
          }

          .checkboxes {
            display: flex;
            flex-wrap: wrap;
            gap: 10px 15px;
          }
        }
        .right {
          width: 100%;
          iframe {
            width: 100%;
            height: 500px;
          }
        }
        .colored {
          display: none;
        }
      }
    }
  }
}
