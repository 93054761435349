.footer-sec {
  padding: 32.28px 0px 92px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 87px;
  background: var(--bodycolor);
  .footer-top {
    width: 92.76%;
    margin-left: auto;
  }
  .footer-bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 71px;
    column-gap: 70px;
    max-width: 1766px;
    .ist {
      display: flex;
      flex-direction: column;
      row-gap: 23.85px;
      .footer-logo {
        width: 173px;
        height: 53.153px;
        object-fit: contain;
        cursor: pointer;
      }
      p {
        max-width: 418px;
        color: var(--textcolor);
        line-height: 167%;
      }
    }
    .second,
    .third {
      display: flex;
      flex-direction: column;
      row-gap: 29px;
      .heading {
        color: var(--headingcolor);
        line-height: 100%;
      }
      a {
        color: var(--textcolor);
        font-family: "Inter";
        font-size: 22.823px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration: none;
      }
    }
    .forth {
      display: flex;
      flex-direction: column;
      row-gap: 29px;

      .social-icons {
        display: flex;

        align-items: center;
        column-gap: 30px;
      }
      p {
        max-width: 247px;
        color: var(--textcolor);
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .footer-sec {
    padding: 20px 0px 70px 0px;

    row-gap: 50px;

    .footer-bottom {
      column-gap: 40px;
      .ist {
        row-gap: 18px;

        p {
          line-height: 140%;
        }
      }
      .second,
      .third {
        row-gap: 20px;
        a {
          font-size: 18px;
        }
      }
      .forth {
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        .social-icons {
          column-gap: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .footer-sec {
    padding: 20px 0px 50px 0px;

    row-gap: 40px;

    .footer-bottom {
      column-gap: 40px;
      padding: 0px 50px;
      .ist {
        row-gap: 15px;

        p {
          line-height: 120%;
          max-width: 350px;
        }
      }
      .second,
      .third {
        row-gap: 15px;
        a {
          font-size: 16px;
        }
      }
      .forth {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        .social-icons {
          column-gap: 20px;
          img {
            width: 30px;
            height: 30px;
            object-fit: contain;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .footer-sec {
    padding: 20px 0px;

    row-gap: 15px;

    .footer-bottom {
      display: flex;
      flex-direction: column;
      padding: 0px 20px;
      row-gap: 20px;
      .ist {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        .footer-logo {
          width: 140px;
          height: 50px;
          object-fit: contain;
        }
        p {
          width: 100%;
          line-height: 120%;
        }
      }
      .second,
      .third {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        a {
          font-size: 16px;

          line-height: 100%;
        }
      }
      .forth {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .social-icons {
          display: flex;

          align-items: center;
          column-gap: 20px;
        }
        p {
          width: 100%;
          max-width: unset;
        }
      }
    }
  }
}
