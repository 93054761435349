.hero-sec {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px;
  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    // z-index: -1;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .inner {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;

    h1 {
      line-height: 100%;
      color: var(--headingcolor);
      text-align: center;
      text-transform: uppercase;
    }

    h6 {
      max-width: 900px;
      text-align: center;
      line-height: 140%;
      color: var(--headingcolor);
    }
  }

  .hero-left {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
  }

  .hero-right {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
  }

  .hero-top {
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
    width: 80vw;
    object-fit: cover;
    transition: all 2s ease-out;
  }

  .hero-bottom {
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%);
    width: 80vw;
    object-fit: cover;
    transition: all 2s ease-out;
  }

  .social-icons {
    position: absolute;
    right: 80px;
    bottom: 54px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    a {
      img {
        width: 40px;
        height: 40px;
        filter: brightness(0) invert(1);
      }
    }
  }
}

// @media screen and (max-width: 1500px) {
//   .hero-sec {
//     .inner {
//       h1 {
//         line-height: 100%;
//         color: var(--headingcolor);
//         text-align: center;
//       }
//       h6 {
//         max-width: 900px;
//         text-align: center;
//         line-height: 140%;
//         color: var(--headingcolor);
//       }
//     }
//     .hero-left {
//       position: absolute;
//       left: 0;
//       top: 0;
//       bottom: 0;
//       object-fit: cover;
//     }
//     .hero-right {
//       position: absolute;
//       right: 0;
//       top: 0;
//       bottom: 0;
//       object-fit: cover;
//     }
//   }
// }

@media screen and (max-width: 600px) {
  .hero-sec {
    padding: 20px;

    .inner {
      row-gap: 10px;
    }

    .hero-left {
      display: none;
    }

    .hero-right {
      display: none;
    }

    .hero-top {
      display: none;
    }

    .hero-bottom {
      display: none;
    }

    .social-icons {
      width: 100%;
      position: absolute;
      right: 0%;
      left: 0%;
      justify-content: center;
      bottom: 20px;
      flex-direction: row;
      gap: 15px;
    }
  }
}