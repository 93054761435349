.blogs-sec {
  padding: 200px 100px 150px 100px;

  background: var(--bodycolor);
  position: relative;
  .inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px;
    max-width: 1556px;
    margin: 0px auto;
    position: relative;
    z-index: 4;
    .blog-card {
      border-radius: 26px;
      background: var(--white);
      transition: all 0.5s linear;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 0px 46.238px 0px rgba(42, 190, 2, 0.18);
      }
      .top {
        border-radius: 26px 26px 0px 0px;
        img {
          width: 100%;
          border-radius: 26px 26px 0px 0px;
        }
      }
      .bottom {
        padding: 27px 32px 48px 32px;
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        .heading {
          display: flex;
          align-items: center;
          justify-content: space-between;
          h5 {
            font-size: 24px;
            line-height: 100%;
          }
          img {
            width: 24px;
            height: 24px;
            object-fit: cover;
          }
        }
        .content {
          color: var(--textcolor);
          line-height: 133%;
        }
        .detail {
          display: flex;
          align-items: center;
          column-gap: 12px;
          img {
            width: 60px;
            height: 60px;
            object-fit: cover;
            border-radius: 100%;
          }
          .username {
            display: flex;
            flex-direction: column;
            row-gap: 2px;
            h6 {
              color: var(--headingcolor);
              font-family: "Inter";
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%;
            }
            span {
              color: #718096;

              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
            }
          }
        }
      }
    }
  }
  .left-back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
  }
  .right-back {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }
}

@media screen and (max-width: 1500px) {
  .blogs-sec {
    padding: 100px 80px;

    .inner {
      gap: 40px;
      .blog-card {
        border-radius: 20px;

        .top {
          border-radius: 20px 20px 0px 0px;
          img {
            border-radius: 20px 20px 0px 0px;
          }
        }
        .bottom {
          padding: 20px 25px 40px 25px;
          row-gap: 20px;
          .heading {
            h5 {
              font-size: 22px;
            }
            img {
              width: 20px;
              height: 20px;
              object-fit: cover;
            }
          }
          .detail {
            column-gap: 12px;
            img {
              width: 50px;
              height: 50px;
            }
            .username {
              h6 {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .blogs-sec {
    padding: 80px 60px;
    .inner {
      gap: 30px;
      .blog-card {
        border-radius: 15px;

        .top {
          border-radius: 15px 15px 0px 0px;
          img {
            border-radius: 15px 15px 0px 0px;
          }
        }
        .bottom {
          padding: 20px 20px 30px 20px;
          row-gap: 20px;
          .heading {
            h5 {
              font-size: 20px;
            }
          }
          .detail {
            column-gap: 10px;
            img {
              width: 50px;
              height: 50px;
            }
            .username {
              h6 {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .blogs-sec {
    padding: 20px;

    .inner {
      grid-template-columns: repeat(1, 1fr);
      gap: 30px;
      .blog-card {
        border-radius: 15px;

        .top {
          border-radius: 15px 15px 0px 0px;
          img {
            border-radius: 15px 15px 0px 0px;
          }
        }
        .bottom {
          padding: 20px 20px 30px 20px;
          row-gap: 15px;
          .heading {
            h5 {
              font-size: 20px;
              line-height: 100%;
            }
            img {
              width: 18px;
              height: 18px;
            }
          }

          .detail {
            column-gap: 10px;
            img {
              width: 50px;
              height: 50px;
            }
            .username {
              row-gap: 2px;
              h6 {
                font-size: 18px;
              }
              span {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}
