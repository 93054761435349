.faqs-sec {
  padding-top: 70px;
  background: var(--bodycolor);
  position: relative;
  .inner {
    padding: 0px 100px 100px 100px;
    max-width: 1582px;
    margin: 0px auto;
    .head {
      padding: 12px 276px 18px 276px;
      background: var(--gradient);
      width: fit-content;
      border-radius: 20px;
      margin: 0px auto;
      margin-bottom: 120px;
      h3 {
        color: var(--white);
      }
    }
    .faqs-accordion {
      .accordion {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        align-items: flex-start;
        gap: 25px;
      }
      .accordion-item {
        color: unset;
        background-color: var(--white);
        border-radius: 16.033px;
        background: var(--white);
        box-shadow: 0px 5.01px 16.033px 0px rgba(8, 15, 52, 0.06);
        border: none;
      }

      .accordion-button {
        padding: 0px;
        border-radius: 16.033px;
        color: var(--headingcolor);
        font-family: "Montserrat";
        font-size: 22.046px;
        font-style: normal;
        font-weight: 500;
        line-height: 127.273%; /* 127.273% */
        column-gap: 10px;
      }
      .accordion-button:focus {
        border: none;
        box-shadow: none;
      }
      .accordion-item {
        border: none;
        padding: 33px;
        display: flex;
        flex-direction: column;
        row-gap: 19px;
      }
      .accordion-button:not(.collapsed) {
        color: unset;
        background-color: var(--white);
        box-shadow: unset;
      }
      .accordion-button:not(.collapsed)::after {
        background-image: url(/assets/images/open.svg);

        transform: var(--bs-accordion-btn-icon-transform);
      }
      .accordion-button::after {
        flex-shrink: 0;
        width: var(--bs-accordion-btn-icon-width);
        height: var(--bs-accordion-btn-icon-width);
        margin-left: auto;

        content: "";
        background-image: url(/assets/images/close.svg);
        background-repeat: no-repeat;
        background-size: cover;
        width: 41px;
        height: 41px;
        background-position: center;
        transition: var(--bs-accordion-btn-icon-transition);
      }
      .accordion-body {
        padding: 0px;
        color: var(--textcolor);
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 166%; /* 166.667% */
      }
    }
  }
  .left-back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
  }
  .right-back {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }
}

@media screen and (max-width: 1500px) {
  .faqs-sec {
    padding-top: 70px;
    .inner {
      padding: 0px 100px 100px 100px;
      .head {
        padding: 10px 200px 14px 200px;

        margin-bottom: 60px;
      }
      .faqs-accordion {
        .accordion {
          gap: 25px;
        }

        .accordion-button {
          font-size: 18px;
        }
        .accordion-item {
          padding: 30px;
          row-gap: 15px;
        }

        .accordion-body {
          font-size: 17px;
          line-height: 140%; /* 166.667% */
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .faqs-sec {
    padding-top: 50px;
    .inner {
      padding: 0px 60px 60px 60px;
      .head {
        padding: 10px 150px 14px 150px;

        margin-bottom: 40px;
      }
      .faqs-accordion {
        .accordion {
          gap: 20px;
        }

        .accordion-button {
          font-size: 16px;
        }
        .accordion-item {
          padding: 25px;
        }

        .accordion-body {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .faqs-sec {
    padding-top: 20px;
    .inner {
      padding: 0px 20px 20px 20px;
      .head {
        padding: 10px 90px 10px 90px;
        border-radius: 10px;
        margin-bottom: 40px;
      }
      .faqs-accordion {
        .accordion {
          grid-template-columns: repeat(1, 1fr);
          gap: 20px;
        }
        .accordion-item {
          border-radius: 10px;
        }
        .accordion-button::after {
          width: 25px;
          height: 25px;
        }
        .accordion-button {
          border-radius: 10px;
          font-size: 18px;
        }

        .accordion-item {
          padding: 15px;
          row-gap: 10px;
        }

        .accordion-body {
          font-size: 16px;
          line-height: 120%; /* 166.667% */
        }
      }
    }
  }
}
