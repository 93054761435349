.aboutpage-sec {
  position: relative;

  .aboutcontent-sec {
    padding: 141px 100px 100px 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 60px;
    max-width: 1482px;
    width: 100%;
    margin: 0px auto;
    .left {
      width: 50%;
      position: relative;
      z-index: 3;
      img {
        width: 462.965px;
        height: 499.04px;
        object-fit: cover;
      }
    }
    .right {
      width: 50%;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      position: relative;
      z-index: 3;

      h3 {
        background: var(--gradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      div {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        h6 {
          color: var(--textcolor);
        }
      }
    }
  }
  .left-back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    z-index: 1;
  }
  .right-back {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    z-index: 1;
  }
}

@media screen and (max-width: 1280px) {
  .aboutpage-sec {
    .aboutcontent-sec {
      padding: 100px 60px;
      column-gap: 50px;
      .left {
        img {
          width: 400px;
          height: 400px;
          object-fit: cover;
        }
      }
      .right {
        row-gap: 10px;

        div {
          row-gap: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .aboutpage-sec {
    .aboutcontent-sec {
      padding: 20px;
      flex-direction: column;
      gap: 30px;

      .left {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 100%;
          max-width: 350px;
          height: 350px;
          object-fit: cover;
          box-shadow: 0px 0px 17px 0px rgba(42, 190, 2, 0.47);
        }
      }
      .right {
        width: 100%;
        row-gap: 10px;

        div {
          row-gap: 10px;
        }
      }
    }
  }
}
