.terms-sec {
  background: var(--bodycolor);
  padding: 250px 100px 100px 100px;
  .heading {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
  }
  h2 {
    color: var(--headingcolor);
    line-height: 100%;
  }
  h5 {
    color: var(--headingcolor);
    line-height: 100%;
  }
  h6 {
    color: var(--textcolor);
    line-height: 109%;
  }
  ul {
    li {
      color: var(--textcolor);
      line-height: 109%;
    }
  }
  .content {
    max-width: 1778px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    div {
      display: flex;
      flex-direction: column;
      row-gap: 18px;
      section {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        ul {
          display: flex;
          flex-direction: column;
          row-gap: 5px;
          margin: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .terms-sec {
    background: var(--bodycolor);
    padding: 180px 100px 100px 100px;
    .heading {
      margin-bottom: 40px;
    }

    .content {
      row-gap: 20px;
      div {
        row-gap: 15px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .terms-sec {
    background: var(--bodycolor);
    padding: 100px 20px 20px 20px;
    .heading {
      margin-bottom: 20px;
    }

    .content {
      width: 100%;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      div {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        section {
          display: flex;
          flex-direction: column;
          row-gap: 5px;
          ul {
            display: flex;
            flex-direction: column;
            row-gap: 4px;
            margin: 0px;
            padding-left: 20px;
          }
        }
      }
    }
  }
}
