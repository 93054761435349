.contactus-sec {
  padding: 58px 70px;
  background: url("/assets/images/contact.webp");
  .inner {
    max-width: 1580px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 60px;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3,
      h4 {
        line-height: 120%;
        color: var(--white);
      }
    }
    .right {
      width: 554.154px;
      height: 581.211px;
      padding: 40px 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 13px;
      border-radius: 22.046px;
      border: 2.355px solid rgba(255, 255, 255, 0.5);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.05) 0%,
        rgba(255, 255, 255, 0.09) 78.82%
      );
      box-shadow: -50.523px 50.523px 50.523px 0px rgba(255, 255, 255, 0.09)
          inset,
        50.523px -50.523px 50.523px 0px rgba(194, 194, 194, 0.09) inset;
      backdrop-filter: blur(38.90248489379883px);
      input,
      textarea {
        border-radius: 12.025px;
        border: 1.178px solid rgba(255, 255, 255, 0.5);
        background: rgba(255, 255, 255, 0.5);
        box-shadow: -22.062px 22.062px 22.062px 0px rgba(255, 255, 255, 0.2)
            inset,
          22.062px -22.062px 22.062px 0px rgba(194, 194, 194, 0.2) inset;
        backdrop-filter: blur(0px);
        width: 100%;
        padding: 13.03px 17.04px;
        outline: none;
        color: var(--white);
        font-family: "Inter";
        font-size: 22.046px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &::placeholder {
          color: var(--white);
        }
      }
      span {
        color: red;
        font-family: "Montserrat";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .contactus-sec {
    padding: 50px 70px;
    .inner {
      max-width: 1580px;
      column-gap: 50px;

      .right {
        width: 400px;
        height: 400px;
        padding: 30px;
        row-gap: 10px;

        input,
        textarea {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .contactus-sec {
    padding: 50px 20px;
    .inner {
      flex-direction: column;
      gap: 20px;
      .left {
        h3 {
          font-size: 23px;
        }
      }
      .right {
        width: 100%;
        height: unset;
        padding: 20px;

        row-gap: 10px;
        border-radius: 10px;

        input,
        textarea {
          border-radius: 10px;
          padding: 10px 15px;
          font-size: 16px;
        }
      }
    }
  }
}
