.landingabout-sec {
  background: var(--black);
  padding: 77px 0px 0px 0px;
  .head {
    border-bottom: 2.004px solid #9af123;
    background: linear-gradient(90deg, #9af123 0%, rgba(42, 190, 2, 0) 100%);
    padding: 12.03px 186.87px 18.16px 171.36px;
    width: fit-content;
    h3 {
      color: white;
    }
  }
  .inner {
    max-width: 1600px;
    margin: 0px auto;
    padding: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 200px;
    width: 100%;

    .left {
      width: 50%;
      img {
        width: 462.965px;
        height: 499.04px;
        object-fit: cover;
      }
    }
    .right {
      width: 50%;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 20px;
      h3 {
        background: var(--gradient);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      h6 {
        color: var(--white);
        margin-bottom: 40px;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .landingabout-sec {
    padding: 50px 0px 0px 0px;
    .head {
      border-bottom: 2.004px solid #9af123;
      background: linear-gradient(90deg, #9af123 0%, rgba(42, 190, 2, 0) 100%);
      padding: 10px 140px 14px 140px;
      width: fit-content;
      h3 {
        color: white;
      }
    }
    .inner {
      padding: 80px 100px;

      column-gap: 100px;

      .left {
        img {
          width: 400px;
          height: 430px;
        }
      }
      .right {
        row-gap: 15px;
        h6 {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .landingabout-sec {
    padding: 50px 0px 0px 0px;
    .head {
      padding: 10px 70px 14px 70px;
    }
    .inner {
      padding: 60px 60px;

      column-gap: 60px;

      .left {
        img {
          width: 350px;
          height: 370px;
        }
      }
      .right {
        row-gap: 10px;
        h6 {
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .landingabout-sec {
    background: var(--black);
    padding: 20px 0px 0px 0px;
    .head {
      border-bottom: 1px solid #9af123;
      padding: 10px 50px 10px 50px;
    }
    .inner {
      margin: 0px auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;

      .left {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          max-width: 300px;
          width: 100%;
          height: unset;
          object-fit: cover;
        }
      }
      .right {
        width: 100%;

        row-gap: 10px;

        h6 {
          margin-bottom: 0px;
        }
      }
    }
  }
}
