/* Carousel Slider Effect */
.swiper-slide{
  position: relative;
}
.testimonials-sec{
  position: relative;
}
.test-leftback{
  position: absolute;
  left: 0;
  top: -50%;
object-fit: cover;
width: 20vw;
height: 120vh;


}
.test-rightback{
  position: absolute;
  right: 0;
  bottom: -70%;
object-fit: cover;
height: 120vh;
width: 20vw;

}
.swiper-slide::before{
  content: "";
  position: absolute;
  opacity: 0.4;
  background: #2ABE02 !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.swiper-slide-fully-visible::before {
  background: unset !important;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: url(/src/assets/images/prev.svg);

  width: 2vw;
  height: 2vw;
  z-index: 10;

}
.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 10px);
  right: auto;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 80px);
  left: auto;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: url(/src/assets/images/next.svg);

  width: 65.985px;
  height: 65.985px;
  z-index: 10;
}
.swiper-navigation-disabled .swiper-button-prev, .swiper-navigation-disabled .swiper-button-next {
  display: block !important;
}



.testimonials-sec {
  padding: 0px 100px;
  background: var(--bodycolor);
}
.testimonials-sec .head {
  padding: 12px 148px 18px 145px;
  background: var(--gradient);
  width: fit-content;
  border-radius: 20px;
  margin: 0px auto;
  margin-bottom: 120px;
}

.testimonials-sec .head {
  color: var(--white);
}
.swiper-carousel {
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
}
.swiper-carousel .swiper-slide,
.swiper-carousel swiper-slide {
  position: relative;
  width: 62%;
}

/** Swiper styles **/

.swiper {
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;

  padding: 0px 0px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;

  overflow: hidden;
}

.swiper-slide-bg-image {
  position: absolute;
  left: -10%;
  top: -10%;
  width: 120%;
  height: 120%;
  z-index: 0;
}

.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  transform: translate3d(0, 0, 0);
}
.testimonial-card {
  display: flex;
  column-gap: 12px;
  padding: 0px 56px 100px 56px;
  background: var(--white);
  border-radius: 35.192px;
}
.testimonial-card .left {
  display: flex;
  width: 224.349px;
  padding-top: 87px;
  background: #fafafc;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
}
.testimonial-card .left img {
  width: 100%;
}
.testimonial-card .right .testimonailcard-img {
  width: 191.399px;
  height: 134.066px;
  object-fit: cover;
  margin-left: auto;
}
.testimonial-card .right {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-top: 32px;
}
.testimonial-card .right h6 {
  max-width: 600px;
  width: 100%;
  color: var(--textcolor);
}
.swiper-slide-cf25 {
  border-radius: 35.192px;
  background: #fff;
  display: flex;
}
.swiper-slide-bg-image-c61b {
  object-fit: cover;
  border-radius: inherit;
}
.swiper-slide-content-64d1 {
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding: 24px 24px;
  gap: 0px;
}
.swiper-slide-text-a9ae {
  color: rgba(255, 255, 255, 1);
  text-align: center;
  font-size: 24px;
  line-height: 1.5;
  font-weight: bold;
}

@media (min-width: 1280px) {
  .swiper {
    width: 100%;

    height: 560px;

    padding: 0px 0px;
  }

  .swiper-slide {
    width: 33%;
    height: 100%;
  }

  :root {
    --swiper-navigation-top-offset: 50%;
  }
}



@media screen and (max-width:1500px) {
  .testimonials-sec .head {
    padding: 10px 100px 14px 100px;

    margin-bottom: 60px;
  }
  .swiper-slide-text-a9ae {

    font-size: 20px;
    line-height: 1.2;
  }
}
@media screen and (max-width:1280px) {
  .testimonials-sec{
    padding: 50px 60px;
  }
  .testimonials-sec .head {
    padding: 10px 70px 14px 70px;

    margin-bottom: 60px;
  }
  .testimonial-card {
    column-gap: 10px;
    padding: 0px 30px 50px 30px;
    border-radius: 20px;
  }
}


@media screen and (max-width:600px) {
  .test-leftback{
display: none;
  
  
  }
  .test-rightback{
    display: none;

  
  }
  .swiper-navigation-disabled .swiper-button-prev, .swiper-navigation-disabled .swiper-button-next {
    display: none !important;
  }
  .testimonials-sec{
    padding: 20px;
  }
  .testimonials-sec .head {
    padding: 10px 30px 10px 30px;
    border-radius: 10px;
    margin-bottom: 40px;
  }
  .testimonial-card {
    column-gap: 10px;
    padding: 0px 20px 30px 20px;
    border-radius: 15px;
    flex-direction: column;
  }
  .testimonial-card .left{
    padding: 0px;
    width: 140px;
    height: 180px;
    object-fit: cover;
  }
  .testimonial-card .right img{
    display: none;
  }
  .swiper-slide-content-64d1 {

    padding: 16px 16px;
  }

}