.team-sec {
  padding: 100px 0px;
  background: var(--bodycolor);
  .head {
    padding: 12px 148px 18px 145px;
    background: var(--gradient);
    width: fit-content;
    border-radius: 20px;
    margin: 0px auto;
    margin-bottom: 120px;
    h3 {
      color: var(--white);
    }
  }
  .team-cards {
    position: relative;
    width: 100%;
    z-index: 4;
    .cards-inner {
      max-width: 1100px;
      margin: 0px auto;
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 50px;
      .team-card {
        margin: 0px auto;
        width: 290.605px;
        height: 351.733px;
        border-radius: 12.025px;
        box-shadow: 0px 0px 26.455px 8.017px rgba(42, 190, 2, 0.11);
        position: relative;
        overflow: hidden;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
        .detail-div {
          position: absolute;
          bottom: 7px;
          left: 25px;
          right: 25px;
          background: var(--gradient);
          border-radius: 8.017px;
          height: 8.017px;
          color: var(--white);
          transition: all 0.5s linear;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 3px;
          overflow: hidden;
          h6,
          p {
            visibility: hidden;
          }
        }
        &:hover {
          .detail-div {
            height: 82px;
            transition: all 0.5s linear;
            h6,
            p {
              visibility: visible;
              transition: all 0.5s linear;
            }
          }
        }
      }
    }
    .back-div {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 10%;
      bottom: 10%;
      background: var(--gradient);
      width: 100%;
      opacity: 0.5;
    }
  }
}

@media screen and (max-width: 1500px) {
  .team-sec {
    padding: 50px 0px;
    .head {
      padding: 10px 100px 14px 100px;

      margin-bottom: 60px;
    }
    .team-cards {
      width: 100%;
      .cards-inner {
        column-gap: 30px;
        width: 100%;
        .team-card {
          width: unset;
          height: 280px;
          border-radius: 12.025px;
          box-shadow: 0px 0px 26.455px 8.017px rgba(42, 190, 2, 0.11);
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .team-sec {
    padding: 50px 0px;
    .head {
      padding: 10px 70px 14px 70px;
      margin-bottom: 40px;
    }
    .team-cards {
      padding: 0px 60px;
      .cards-inner {
        column-gap: 20px;

        .team-card {
          width: unset;
          height: 250px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .team-sec {
    padding: 20px 0px;
    background: var(--bodycolor);
    .head {
      padding: 10px 30px 10px 30px;
      border-radius: 10px;
      margin-bottom: 40px;
    }
    .team-cards {
      padding: 0px 20px;
      .cards-inner {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        .team-card {
          margin: 0px auto;
          max-width: 300px;
          width: 100%;
          height: 351.733px;
          border-radius: 10px;
          box-shadow: 0px 0px 10px 4px rgba(42, 190, 2, 0.11);

          .detail-div {
            height: 60px;
            transition: all 0.5s linear;
            &:hover {
              height: 60px;
            }
            h6,
            p {
              visibility: visible;
              transition: all 0.5s linear;
            }
          }
        }
      }
      .back-div {
        display: none;
      }
    }
  }
}
