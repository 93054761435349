.blog-sec {
  padding: 197px 100px 140px 100px;
  background: var(--bodycolor);
  position: relative;
  .inner {
    position: relative;
    z-index: 3;
    max-width: 1342px;
    margin: 0px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    .top {
      border-radius: 92px;
      width: 100%;
      object-fit: cover;
      max-height: 686px;
    }
    .center {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      h3 {
        line-height: 100%;
      }
      .content {
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        h6 {
          color: var(--textcolor);
        }
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      column-gap: 30px;
      img {
        width: 50%;
        max-width: 738px;
        max-height: 500px;
        object-fit: cover;
        border-radius: 58px;
      }
      h6 {
        width: 50%;
        color: var(--textcolor);
      }
    }
  }
  .left-back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
  }
  .right-back {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }
}

@media screen and (max-width: 1500px) {
  .blog-sec {
    padding: 150px 80px 100px 80px;
    .inner {
      row-gap: 40px;
      .top {
        border-radius: 40px;

        max-height: 600px;
      }
      .center {
        row-gap: 20px;
        .content {
          row-gap: 20px;
        }
      }
      .bottom {
        column-gap: 30px;
        img {
          border-radius: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .blog-sec {
    padding: 100px 20px 20px 20px;
    .inner {
      row-gap: 20px;
      .top {
        border-radius: 20px;

        max-height: 300px;
      }
      .center {
        row-gap: 10px;
        h3 {
          line-height: 100%;
        }
        .content {
          row-gap: 10px;
        }
      }
      .bottom {
        flex-direction: column;
        row-gap: 20px;
        column-gap: 30px;
        img {
          width: 100%;
          max-width: 350px;
          max-height: 350px;

          border-radius: 20px;
        }
        h6 {
          width: 100%;
          color: var(--textcolor);
        }
      }
    }
  }
}
