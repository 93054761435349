@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --bodycolor:#F6F9FF;
    --headingcolor: #2B2B2B;
    --textcolor: #718096;
    --black: #000;
    --white: #fff;
    --gradient:  linear-gradient(106deg, #9AF123 10.92%, #81E61B 30.36%, #44CA09 71.59%, #2ABE02 88.71%);

  }
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}
.pointer{
    cursor: pointer !important;
}

  .imgshadow{
    box-shadow: 0px 0px 63.132px 0px rgba(42, 190, 2, 0.47);
    border-radius: 19.04px;
  }
h1,h2,h3,h4,h5,h6,p{
    margin: 0px;
}
.about-sec{
    background: var(--bodycolor);
}
h1{
    text-align: center;
    font-family: "Montserrat";
    font-size: 74px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
} 
h2{
    font-family: "Montserrat";
font-size: 61px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
h3{
    font-family: "Montserrat";
font-size: 48.1px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
h4{
    font-family: "Montserrat";
font-size: 36.075px;
font-style: normal;
font-weight: 600;
line-height: normal; 
}
h5{
    font-family: "Inter";
font-size: 30px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
h6,li{
    font-family: "Inter";
    font-size: 22.046px;
    font-style: normal;
    font-weight: 400;
}
p{
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}
.btnprimary{
    background:var(--gradient) ;
    color: var(--white);
    padding: 16.03px 52.11px;
font-family: "Inter";
font-size: 22.046px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;
border: none;
border-radius: 6.013px;
cursor: pointer;
}



@media screen and (max-width:1500px) {
    h1{
        font-size: 55px;

    } 
    h2{

    font-size: 45px;

    }
    h3{

    font-size: 38px;

    }
    h4{
    font-size: 30px;
    }
    h5{

    font-size: 24px;

    }
    h6,li{
        font-size: 20px;

    }
    p{
        font-size: 17px;

    }
    .btnprimary{

        padding: 13px 40px;

    font-size:18px;

    }
    
}



@media screen and (max-width:1280px) {
    h1{
        font-size: 40px;

    } 
    h2{

    font-size: 35px;

    }
    h3{

    font-size: 30px;

    }
    h4{
    font-size: 24px;
    }
    h5{

    font-size: 20px;

    }
    h6,li{
        font-size: 18px;

    }
    p{
        font-size: 16px;

    }
    .btnprimary{

        padding: 10px 30px;

    font-size:16px;

    }
    
}


@media screen and (max-width:600px) {
    .left-back{
        display: none !important;
    }
    .right-back{
        display: none !important;
    }
    h1{
        font-size: 35px;

    } 
    h2{

    font-size: 28px;

    }
    h3{

    font-size: 24px;

    }
    h4{
    font-size: 20px;
    }
    h5{

    font-size: 18px;

    }
    h6,li{
        font-size: 16px;

    }
    p{
        font-size: 15px;

    }
    .btnprimary{

        padding: 10px 30px;

    font-size:15px;

    }
    .imgshadow{
        box-shadow: 0px 0px 40px 0px rgba(42, 190, 2, 0.47);
        border-radius: 10px;}
}