.navbar-sec {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  padding: 53px 68px 0px 68px;
  .navbar {
    padding: 0px;
    display: flex;
    align-items: center;
    background: transparent;
    .navbar-brand {
      img {
        width: 240px;
        height: 85px;
        object-fit: contain;
        padding: 0px;
      }
    }
    .navbar-collapse {
      display: flex;
      align-items: center;
      .navbar-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 50px;
        border-radius: 42px;
        border: 1px solid #fff;
        background: rgba(0, 0, 0, 0.01);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(13px);

        padding: 21px 74px;
        .nav-item {
          padding: 0px;
          a {
            padding: 0px;
            color: var(--headingcolor);
            font-family: "Inter";
            font-size: 22.046px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            text-decoration: none;
          }
          a.active {
            color: #2abe02 !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .navbar-sec {
    padding: 40px 40px 0px 40px;
    .navbar {
      .navbar-brand {
        img {
          width: 200px;
          height: 70px;
        }
      }
      .navbar-collapse {
        .navbar-nav {
          column-gap: 30px;

          padding: 20px 50px;
          .nav-item {
            padding: 0px;
            a {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .navbar-sec {
    padding: 40px 40px 0px 40px;
    .navbar {
      .navbar-brand {
        img {
          width: 170px;
          height: 60px;
          object-fit: contain;
        }
      }
      .navbar-collapse {
        .navbar-nav {
          padding: 15px 40px;
          .nav-item {
            padding: 0px;
            a {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .navbar-sec {
    padding: 20px;
    z-index: 10;
    .navbar-toggler {
      padding: 0px;
      border: none;
    }
    .navbar {
      .navbar-brand {
        img {
          width: 140px;
          height: 50px;
          object-fit: contain;
        }
      }
      .navbar-collapse {
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        border: 1px solid #fff;
        background: #f6f9ff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 20px;
        row-gap: 5px;
        .navbar-nav {
          row-gap: 5px;
          border-radius: 20px;
          padding: 0;
          width: 100%;
          border: none;
          background: #f6f9ff;
          box-shadow: unset;

          .nav-item {
            padding: 0px;
            a {
              font-size: 16px;
              color: var(--headingcolor) !important;
            }
            a.active {
              color: #2abe02 !important;
            }
          }
        }
      }
    }
  }
}
