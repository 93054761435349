.trustedbrands-sec {
  padding: 75px 0px 0px 0px;
  background: var(--bodycolor);
  position: relative;
  .back-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 25%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  .back-img2 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 20%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  .head {
    background: var(--gradient);
    padding: 11.63px 160px 17.37px 140px;
    width: fit-content;
    border-radius: 0px 20px 20px 0px;
    h3 {
      color: white;
    }
  }
  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1600px;
    width: 100%;
    padding: 54px 100px;
    column-gap: 200px;
    margin: 0px auto;

    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      row-gap: 14px;
      h2 {
        color: var(--headingcolor);
        line-height: 120%;
      }
      h6 {
        color: var(--textcolor);
        line-height: 136%;
      }
    }
    .right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      position: relative;
      z-index: 2;
      img {
        width: 558.106px;
        height: 587.224px;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .trustedbrands-sec {
    padding: 50px 0px 0px 0px;

    .head {
      padding: 10px 100px 14px 100px;
    }
    .inner {
      padding: 50px 100px;
      column-gap: 100px;

      .left {
        width: 50%;
        display: flex;
        flex-direction: column;
        row-gap: 14px;
        h2 {
          color: var(--headingcolor);
          line-height: 110%;
        }
        h6 {
          color: var(--textcolor);
          line-height: 136%;
        }
      }
      .right {
        width: 50%;

        img {
          width: 400px;
          height: 410px;
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .trustedbrands-sec {
    padding: 50px 0px 0px 0px;

    .head {
      padding: 10px 70px 14px 70px;
    }
    .inner {
      padding: 50px 100px;
      column-gap: 100px;

      .right {
        img {
          width: 350px;
          height: 360px;
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .trustedbrands-sec {
    padding: 20px 0px 0px 0px;

    .back-img {
      display: none;
    }
    .head {
      padding: 10px 30px 10px 30px;
      border-radius: 0px 10px 10px 0px;
    }
    .inner {
      width: 100%;
      padding: 20px;
      flex-direction: column;
      row-gap: 20px;

      .left {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        h2 {
          color: var(--headingcolor);
          line-height: 100%;
        }
        h6 {
          color: var(--textcolor);
          line-height: 136%;
        }
      }
      .right {
        width: 100%;

        img {
          max-width: 300px;
          margin: 0px auto;
          width: 100%;
          height: unset;
        }
      }
    }
  }
}
