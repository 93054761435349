.banner-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 700px;
  .banner-layer,
  .backimg,
  .back-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .backimg,
  .back-video {
    z-index: 2;
  }
  .banner-layer {
    z-index: 4;
  }
  h1 {
    position: relative;
    z-index: 5;
    color: var(--white);
    line-height: 100%;
  }
}

@media screen and (max-width: 1500px) {
  .banner-sec {
    height: 600px;
  }
}
@media screen and (max-width: 1280px) {
  .banner-sec {
    height: 500px;
    padding: 20px;
  }
}
