.errorpage-sec {
  background: var(--bodycolor);

  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 26px;
    max-width: 572px;
    margin: 0px auto;
    img {
      width: 100%;
    }
    div {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      align-items: center;
      color: black;
      margin-bottom: 8px;
      h5 {
        line-height: 100%;
        font-weight: 700;
      }
      h6 {
        line-height: 100%;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .errorpage-sec {
    .inner {
      row-gap: 15px;

      div {
        row-gap: 10px;
        margin-bottom: 0px;
      }
    }
  }
}
