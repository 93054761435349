.services-new-main{
    background: var(--bodycolor);
    padding: 100px 0;
    .heading-container{
        background: var(--gradient);
        width: 35%;
        margin-left: auto;
        border-radius: 20px 0 0 20px;
        padding: 13px 0;
        .services-heading{
            display: flex;
            justify-content: center;
            align-items: center;

            h2{
                font-family: "Montserrat";
                font-size: 48.1px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: #fff;
            }
        }
    }

    .services-card-container{
        width: 75%;
        margin: auto;
        padding: 25px 0;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 20px;
        margin-top: 50px;
        grid-gap: 50px;
        .service-card{
            border-radius: 20px;
            width: 95%;
            background-color: var(--white-color);
            box-shadow: 0px 10px 15px rgba(8, 14, 28, 0.06);
            cursor: pointer;
            transition: transform 0.3s ease-in-out;
            overflow: hidden;

            .card-image{
                width: 100%;
                img{
                    width: 100%;
                    border-radius: 20px 20px 0 0;
                    transform: scale(1.1);

                }
            }
            .service-content{
                padding: 40px 30px;
                transition: background-color 0.5s ease-in-out;

                h3{
                    font-size: 25px;
                    color: #000;
                }
                p{
                    font-size: 18px;
                    color: #737887;
                    margin-top: 15px;
                }
            }
            &:hover{
                background: var(--gradient);

                .card-image{
                    img{
                        transform: scale(0.9s);
                    }
                }
                .service-content{
                transition: background-color 0.5s ease-in-out;

                    h3{
                        color: #fff;
                    }
                    p{
                        color: #fff;
                    }
                }
            }
        }
    }
    
}

@media screen and (max-width:1700px) {
    .services-new-main{
        .services-card-container{
            width: 85%;
        }
    }
    
}
@media screen and (max-width:1500px) {
    .services-new-main{
        .services-card-container{
            width: 85%;

            .service-card{
                .service-content{
                    padding: 25px;
                    p{
                        font-size: 16px;
                    }
                }
            }
        }
    }
    
}
@media screen and (max-width:1300px) {
    .services-new-main{
        padding: 60px 0;
        .services-card-container{
            grid-gap: 30px;

            .service-card{
                width: 100%;
                .service-content{
                    padding: 20px;
                    h3{
                        font-size: 20px;
                    }
                    p{
                        font-size: 15px;
                        line-height: normal;
                    }
                }
            }
        }
    }
    
}
@media screen and (max-width:600px) {
    .services-new-main{
        padding: 25px 0;
        .heading-container{
            border-radius: 10px 0 0 10px;
            width: 70%;
            padding: 10px 0;
            .services-heading{
                h2{
                    font-size: 24px;
                }
            }
        }
        .services-card-container{
            grid-template-columns: repeat(1, 1fr);
            width: 90%;
            margin-top: 20px;
            .service-card{
                width: 95%;
                margin: auto;

                .service-content{
                    padding: 30px;
                }
            }
        }
    }
}