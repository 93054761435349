.counter-sec {
  padding: 100px;
  .head {
    padding: 7px 93px;
    background: var(--gradient);
    width: fit-content;
    border-radius: 20px;
    margin: 0px auto;
    margin-bottom: 130px;
    h3 {
      color: var(--white);
    }
  }
  .projects {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 100px;
    max-width: 1826px;
    margin: 0px auto;
    .ist {
      width: 268.487px;
      height: 268.487px;
      border-radius: 42px;
      border: 5px solid var(--headingcolor);
      box-shadow: 0px 0px 26.455px 8.017px rgba(42, 190, 2, 0.11);
      transform: rotate(-45deg);
      position: relative;
      transition: all 1s linear;
      &:hover {
        border-radius: 100%;
        transition: all 1s linear;
      }
      .content {
        transform: rotate(45deg);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        h2 {
          position: absolute;
          top: 60px;
          line-height: 100%;
          background: var(
            --Gradient,
            linear-gradient(
              106deg,
              #9af123 10.92%,
              #81e61b 30.36%,
              #44ca09 71.59%,
              #2abe02 88.71%
            )
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        h5 {
          position: absolute;
          font-size: 26px;
          top: 130px;
          display: flex;
          flex-direction: column;
          align-items: center;
          line-height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .counter-sec {
    padding: 80px 80px;

    .projects {
      column-gap: 80px;
      .ist {
        width: 220px;
        height: 220px;

        .content {
          h2 {
            font-size: 50px;
            top: 60px;
          }
          h5 {
            top: 120px;
            font-size: 22px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1330px) {
  .counter-sec {
    padding: 0px 50px 80px 50px;
    .head {
      margin-bottom: 100px;
    }
    .projects {
      column-gap: 60px;
      .ist {
        width: 165px;
        height: 165px;
        border: 3px solid var(--headingcolor);
        border-radius: 20px;

        .content {
          h2 {
            font-size: 40px;
            top: 40px;
          }
          h5 {
            top: 85px;
            font-size: 18px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .counter-sec {
    padding: 20px 20px 60px 20px;
    .head {
      padding: 10px 30px 10px 30px;
      border-radius: 10px;
      margin-bottom: 80px;
    }
    .projects {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      column-gap: 100px;
      row-gap: 100px;
      .ist {
        overflow: hidden;
        width: 200px;
        height: 200px;
        border-radius: 15px;
        border: 3px solid var(--headingcolor);
        box-shadow: 0px 0px 15px 3px rgba(42, 190, 2, 0.11);

        .content {
          width: 100%;
          h2 {
            top: 50px;
          }
          h5 {
            font-size: 20px;
            top: 100px;
          }
        }
      }
    }
  }
}
