.whyus-sec {
  padding: 130px 100px;
  background: var(--bodycolor);
  .head {
    padding: 12px 148px 18px 145px;
    background: var(--gradient);
    width: fit-content;
    border-radius: 20px;
    margin: 0px auto;
    margin-bottom: 120px;
    h3 {
      color: var(--white);
    }
  }
  .whyus-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 60px;
    justify-content: space-between;
    max-width: 1420px;
    margin: 0px auto;
    position: relative;
    z-index: 4;
    .whyus-card {
      width: 400px;
      height: 351px;
      padding: 39px 70px 80px 70px;
      border-radius: 14.529px;
      background: #fff;
      box-shadow: 0px 56px 63.5px -32px rgba(42, 190, 2, 0.44);
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0px auto;
      row-gap: 50px;

      &:hover {
        .card-icon {
          display: none;
        }
        .hover-icon {
          display: block;
        }
        .topbox {
          top: -160px;
          transition: all 0.5s linear;
        }
        .bottombox {
          bottom: -80px;
          transition: all 0.5s linear;
        }
        h6 {
          color: var(--white);
          transition: all 0.5s linear;
        }
      }
      h6 {
        transition: all 0.5s linear;

        color: var(--textcolor);
        line-height: 136%;
        position: relative;
        z-index: 2;
        text-align: center;
      }
      .card-icon {
        color: var(--white);
        position: relative;
        z-index: 2;
        width: 45.918px;
        height: 45.918px;
        object-fit: contain;
      }
      .hover-icon {
        display: none;
        position: relative;
        z-index: 2;
        width: 45.918px;
        height: 45.918px;
        object-fit: contain;
      }
      .topbox,
      .bottombox {
        transition: all 0.5s linear;

        width: 458px;
        border-radius: 100%;
        background: var(--gradient);
      }

      .topbox {
        height: 167px;

        position: absolute;
        top: -59px;
        left: -27px;
        right: -27px;
        overflow: hidden;
      }
      .bottombox {
        height: 317px;
        position: absolute;
        bottom: -300px;
        left: -27px;
        right: -27px;
        overflow: hidden;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .whyus-sec {
    padding: 50px 100px;
    background: var(--bodycolor);
    .head {
      padding: 10px 100px 14px 100px;

      margin-bottom: 60px;
    }
    .whyus-cards {
      column-gap: 40px;

      .whyus-card {
        width: 300px;
        height: 270px;
        padding: 30px 50px 60px 50px;

        row-gap: 40px;

        &:hover {
          .card-icon {
            display: none;
          }
          .hover-icon {
            display: block;
          }
          .topbox {
            top: -160px;
            transition: all 0.5s linear;
          }
          .bottombox {
            bottom: -50px;

            transition: all 0.5s linear;
          }
        }
        h6 {
          transition: all 0.5s linear;

          color: var(--textcolor);
          line-height: 136%;
          position: relative;
          z-index: 2;
        }
        .card-icon {
          color: var(--white);
          position: relative;
          z-index: 2;
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
        .hover-icon {
          width: 40px;
          height: 40px;
        }
        .topbox,
        .bottombox {
          transition: all 0.5s linear;
          margin: 0px auto;
          width: 340px;
          border-radius: 100%;
          background: var(--gradient);
        }

        .topbox {
          height: 130px;
          top: -40px;
        }
        .bottombox {
          height: 200px;
          position: absolute;
          bottom: -190px;
          left: -27px;
          right: -27px;
          overflow: hidden;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .whyus-sec {
    padding: 50px 60px;
    .head {
      padding: 10px 70px 14px 70px;
      margin-bottom: 40px;
    }
    .whyus-cards {
      column-gap: 30px;

      .whyus-card {
        width: 280px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .whyus-sec {
    padding: 20px;
    .head {
      padding: 10px 30px 10px 30px;
      border-radius: 10px;
      margin-bottom: 40px;
    }
    .whyus-cards {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;

      .whyus-card {
        max-width: 300px;
        margin: 0px auto;
        width: 100%;
        height: 250px;
        padding: 20px 40px;
        border-radius: 10px;
        box-shadow: 0px 30px 30px -32px rgba(42, 190, 2, 0.44);

        row-gap: 50px;

        &:hover {
          .card-icon {
            display: none;
          }
          .hover-icon {
            display: block;
          }
          .topbox {
            top: -160px;
            transition: all 0.5s linear;
          }
          .bottombox {
            bottom: -120px;
            transition: all 0.5s linear;
          }
          h6 {
            color: var(--white);
            transition: all 0.5s linear;
          }
        }
        h6 {
          color: var(--textcolor);
          line-height: 120%;
        }
        .card-icon {
          display: block;
        }
        .hover-icon {
          display: none;
        }
        .topbox,
        .bottombox {
          width: 120vw;
          left: auto;
          right: auto;
        }

        .topbox {
          height: 167px;

          position: absolute;
          top: -90px;
          left: auto;
          right: auto;
          overflow: hidden;
        }
        .bottombox {
          height: 250px;

          bottom: -240px;
        }
      }
    }
  }
}
