.portfolio-sec {
  position: relative;
  background: var(--bodycolor);
  .outer {
    padding: 100px;
    position: relative;
    z-index: 3;
    .head {
      padding: 12px 115px 18px 115px;
      background: var(--gradient);
      width: fit-content;
      border-radius: 20px;
      margin: 0px auto;
      margin-bottom: 120px;
      h3 {
        color: var(--white);
      }
    }
    .inner {
      max-width: 1800px;
      width: 100%;
      margin: 0px auto;
      .portfolio-cards {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        column-gap: 3.333%;
        row-gap: 80px;
        .portfolio-card {
          border-radius: 22px;
          width: 31.1%;
          height: 292.71px;
          position: relative;
          overflow: hidden;

          &:hover {
            .main-img {
              transform: translateY(calc(-100% + 290px));
              transition: all 6s linear;
            }
            .content {
              transition: all 2s linear;
              opacity: 0;
            }
            .back-img {
              opacity: 0;
              transition: all 2s linear;
            }
          }
          .content {
            position: absolute;
            z-index: 3;
            bottom: 14px;
            left: 24px;
            right: 24px;
            color: white;
            transition: all 2s linear;
            opacity: 1;
          }
          .main-img {
            object-fit: cover;
            transition: all 6s linear;
            width: 100%;
          }
          .back-img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 50.52%,
              rgba(0, 0, 0, 0.79) 72%,
              #000 93%
            );
            opacity: 0.5;
            transition: all 2s linear;
          }
        }
      }
    }
  }
  .left-back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
  }
  .right-back {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }
}

@media screen and (max-width: 1500px) {
  .portfolio-sec {
    .outer {
      padding: 100px 80px;
      .head {
        padding: 12px 100px 18px 100px;
        border-radius: 15px;
        margin-bottom: 100px;
      }
      .inner {
        max-width: 1800px;
        width: 100%;
        margin: 0px auto;
        .portfolio-cards {
          column-gap: 3.333%;
          row-gap: 40px;
          .portfolio-card {
            border-radius: 18px;
            width: 31.1%;
            height: 292.71px;

            .content {
              left: 20px;
              right: 20px;
              line-height: 120%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .portfolio-sec {
    padding: 80px 50px;
    .head {
      padding: 10px 70px 14px 70px;
      margin-bottom: 60px;
    }
    .inner {
      .portfolio-cards {
        column-gap: 2%;
        row-gap: 30px;
        .portfolio-card {
          width: 32%;
          height: 292.71px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .portfolio-sec {
    padding: 20px;
    .outer {
      padding: 0px;
      .head {
        padding: 10px 30px 10px 30px;
        border-radius: 10px;
        margin-bottom: 20px;
      }
      .inner {
        .portfolio-cards {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          .portfolio-card {
            border-radius: 15px;
            width: 100%;
            height: 292.71px;
            .content {
              left: 20px;
              right: 20px;
            }
          }
        }
      }
    }
  }
}
